const dev = process.env.NODE_ENV !== 'production';

export const BRIEF_API_URL = process.env.BRIEF_API_URL || 'https://dev.voicebrief.app/v1';

export const NEXTJS_API_URL = process.env.NEXT_PUBLIC_MAIN_URL || 'http://localhost:3000';

export const DEFAULT_IMAGE =
  'https://storage.googleapis.com/brief-bucket-dev/resources/private/space_placeholder.png';

export const DEFAULT_SUBDOMAIN = 'brief';

import store2 from 'store2';
var store = store2.namespace('brief');
var LocalStorage = {
  getItem: function getItem(itemName) {
    return store.get(itemName);
  },
  setItem: function setItem(itemName, item) {
    return store.set(itemName, item);
  },
  deleteItem: function deleteItem(itemName) {
    return store.remove(itemName);
  },
  removeItem: function removeItem(itemName) {
    return store.remove(itemName);
  },
  clear: function clear() {
    return store.clear();
  },
  getAllItems: function getAllItems() {
    return localStorage.getAllKeys().then(function (keys) {
      var fetchKeys = keys.filter(function (k) {
        return k.startsWith('');
      });
      return localStorage.multiGet(fetchKeys);
    }).then(function (result) {
      return result.map(function (r) {
        return JSON.parse(r[1]);
      });
    });
  }
};
export default LocalStorage;
import jwt_decode from 'jwt-decode';
import { ApiRequest } from 'models/services.model';
import { User } from 'store/types';
import { Middleware } from 'next-connect';
import { NextApiResponse } from 'next';
import { DEFAULT_SUBDOMAIN } from 'constants/global';

const secret = process.env.SECRET_COOKIE_PASSWORD;

export const getBriefCookies = (cookies: string) => {
  const regex = /Authorization=Bearer\+(?<Authorization>[^;]*).*deviceToken=(?<deviceToken>[^;]*)/gm;

  //console.log(m);
  const {
    groups: { Authorization, deviceToken }
  } = regex.exec(cookies);

  const decoded: { user: User } = jwt_decode(Authorization);
  const user = decoded.user;

  return {
    Authorization,
    deviceToken,
    user
  };
};

export const composeHeaders = (req: ApiRequest) => {
  const { Authorization, deviceToken, subdomain } = getReqSession(req);

  const headers: any = {
    'Content-Type': 'application/json',
    deviceToken: deviceToken,
    subdomain: subdomain
  };

  if (Authorization) {
    headers.Authorization = 'Bearer ' + Authorization;
  }

  return headers;
};

export const getReqSession = (req: ApiRequest) => {
  const Authorization: string = req.session.get('Authorization') || '';
  const deviceToken: string = req.session.get('deviceToken') || '';
  const subdomain: string = getSubdomainFromHost(req?.headers?.host);
  const user: User = req.session.get('user') || undefined;

  return { Authorization, deviceToken, subdomain, user };
};

export const SessionMiddleware = (req: ApiRequest, res, next) => {
  const headers = composeHeaders(req);
  req.headers = headers;
  next(); // call to proceed to next in chain
};

export const getSubdomainFromHost = (host = ""): string => {
  if (!host && process?.browser) {
    try {
      host = window?.location?.host
    } catch (e) { }
    finally {
      host = ""
    }
  }
  const subdomain = host.split('.')[1] ? host.split('.')[0] : DEFAULT_SUBDOMAIN;
  return subdomain;
};

export const getParameterByName = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search);
  return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
